import { getLocalStorage, setLocalStorage } from 'lib/localStorage';
import React, { useState } from 'react';

import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Projects } from './projects';
import { Invoices } from './invoices';

export const Export: React.FC = () => {
  const [type, setType] = useState<string>(getLocalStorage('export-type') || 'projects');

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setLocalStorage('export-type', key);
      setType(key);
    }
  };

  return (
    <Row className="department-tabs-wrapper">
      <Col>
        <Tabs defaultActiveKey={type} id="tabs-export" className="mb-3" onSelect={(k) => handleSelectTab(k)}>
          <Tab eventKey="projects" title="Projekter">
            {'projects' === type && <Projects />}
          </Tab>
          <Tab eventKey="invoices" title="Fakturaer">
            {'invoices' === type && <Invoices />}
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};
