import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import { Calendar3, GraphUpArrow } from 'react-bootstrap-icons';
import { TurnoverObject } from './types';

import moment from 'moment';

interface Props {
  turnoverDataMonth: TurnoverObject;
}

// Date range function
const getDateRangeMonth = (monthNumber: number) => {
  const startDate = moment({ month: monthNumber - 1 })
    .startOf('month')
    .format('DD.MM.YYYY');
  const endDate = moment({ month: monthNumber - 1 })
    .endOf('month')
    .format('DD.MM.YYYY');
  return { startDate, endDate };
};

const MonthView: React.FC<Props> = ({ turnoverDataMonth }) => (
  <>
    {Object.keys(turnoverDataMonth).map((key: string) => {
      const data = turnoverDataMonth[Number(key)];

      const monthName = new Date(data.year, data.month - 1, 1).toLocaleString('default', { month: 'long' });
      const startDate = getDateRangeMonth(data.month).startDate;
      const endDate = getDateRangeMonth(data.month).endDate;
      const formattedTurnover = new Intl.NumberFormat('da-DK').format(data.total_turnover);

      return (
        <Card key={data.month}>
          <Card.Body>
            <Card.Title>
              <Badge>{monthName}</Badge>
            </Card.Title>
            <Card.Subtitle>
              <Calendar3 />
              <span className="text-muted">{`${startDate} - ${endDate}`}</span>
            </Card.Subtitle>
            <Card.Text>
              <GraphUpArrow />
              Oms.: <span className="turnover">{`${formattedTurnover} DKK`}</span>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    })}
  </>
);

export default MonthView;
