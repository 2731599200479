import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { TripDropZone } from './trips/trip-drop-zone';
import { LogisticsGridTripsContent } from './trips-content';
import { LogisticsGridTripsHeading } from './trips-heading';

import type { TripOverviewInterface } from './types';
import { PlusCircleFill } from 'react-bootstrap-icons';

export const LogisticsGridTripsOverview: React.FC<TripOverviewInterface> = ({
  trip,
  cars,
  crews,
  times,
  deliverProjects,
  returnProjects,
  removeLogisticItem,
}) => {
  return (
    <Row className="logistics-grid-trips mt-3">
      {trip.logistics.length !== 0 &&
        trip.logistics.map((logistic, index) => {
          return (
            <TripDropZone
              key={logistic.logistics_id}
              customKey={`logistic-${logistic.logistics_id.toString()}`}
              customId={logistic.logistics_id}
              logistic={logistic}
              index={index}
            >
              <>
                <LogisticsGridTripsHeading logistic={logistic} allCars={cars} selectedCars={logistic.logistics_cars} removeLogisticItem={removeLogisticItem} />{' '}
                <LogisticsGridTripsContent
                  logistic={logistic}
                  allCrews={crews}
                  allTimes={times}
                  selectedCrews={logistic.logistics_crews}
                  selectedProjects={logistic.logistics_projects}
                  deliverProjects={deliverProjects}
                  returnProjects={returnProjects}
                  removeLogisticItem={removeLogisticItem}
                />
              </>
            </TripDropZone>
          );
        })}
      <TripDropZone customKey={`new-logistic-${trip.trip_id}`} customId={trip.trip_id} logistic={null} index={trip.logistics.length}>
        <Col className="new-drag-area">
          <PlusCircleFill />
          <p>Træk og slip et element fra drag and drop området</p>
        </Col>
      </TripDropZone>
    </Row>
  );
};
