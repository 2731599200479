import React from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { Calendar3, GraphUpArrow, CardList } from 'react-bootstrap-icons';

export const HomePage: React.FC = () => {
  return (
    <>
      <Row>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <CardList size={60} />
              <Card.Title className="mt-2">Ordreliste</Card.Title>
              <Card.Text>Some quick example text to build on the card title</Card.Text>
              <Button href="/order-list" className="dark-blue">
                Gå til ordreliste
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <GraphUpArrow size={60} />
              <Card.Title className="mt-2">Omsætning</Card.Title>
              <Card.Text>Some quick example text to build on the card title</Card.Text>
              <Button href="/turnover" className="dark-blue">
                Gå til omsætning
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <Calendar3 size={60} />
              <Card.Title className="mt-2">Logistik</Card.Title>
              <Card.Text>Some quick example text to build on the card title</Card.Text>
              <Button href="/logistics-tool" className="dark-blue">
                Gå til logistik
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
