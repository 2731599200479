import React, { useRef, useState } from 'react';
import { Row, Col, Alert, CloseButton, Button, Overlay, Popover } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle, PersonFill } from 'react-bootstrap-icons';

import type { TripContentInterface, CrewInterface, ProjectInterface, DrivingTimesInterface } from './types';

export const LogisticsGridTripsContent: React.FC<TripContentInterface> = ({
  logistic,
  allCrews,
  allTimes,
  selectedCrews,
  deliverProjects,
  returnProjects,
  selectedProjects,
  removeLogisticItem,
}) => {
  const findCrew = (crewId: number): CrewInterface | null => {
    let foundCrew: CrewInterface | null = null;

    for (const crew of allCrews) {
      if (crew.crew_id === crewId) {
        foundCrew = crew;
      }
    }

    return foundCrew;
  };

  const findDrivingTimes = (drivingTimeId: number): DrivingTimesInterface | null => {
    let foundDrivingTimes: DrivingTimesInterface | null = null;

    for (const time of allTimes) {
      if (time.driving_times_id === drivingTimeId) {
        foundDrivingTimes = time;
      }
    }

    return foundDrivingTimes;
  };

  const findProject = (projectId: number): ProjectInterface | null => {
    let foundProject: ProjectInterface | null = null;

    for (const tmpProjectId of selectedProjects) {
      if (deliverProjects.some((project) => project.projects_id === tmpProjectId) === true) {
        for (const project of deliverProjects) {
          if (project.projects_id === projectId) {
            foundProject = { ...project, deliverType: 'delivery' };
          }
        }
      }

      if (returnProjects.some((project) => project.projects_id === tmpProjectId) === true) {
        for (const project of returnProjects) {
          if (project.projects_id === projectId) {
            foundProject = { ...project, deliverType: 'returning' };
          }
        }
      }
    }

    return foundProject;
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShow(!show);
    setTarget(event.target as HTMLElement);
  };

  return (
    <>
      <Row className="mb-2">
        <Col sm={12}>
          {selectedCrews.length !== 0 &&
            selectedCrews.map((crewId, index) => {
              const crew = findCrew(crewId);

              if (crew) {
                return (
                  <Alert key={`${logistic.logistics_id}-${crewId}-${index}`} variant="secondary">
                    <PersonFill /> {crew.crew_name}
                    <CloseButton className="btn-delete" onClick={() => removeLogisticItem('crew', crewId, logistic)} />
                  </Alert>
                );
              } else {
                return null;
              }
            })}
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          {selectedProjects.length !== 0 &&
            selectedProjects.map((projectId, index) => {
              const project = findProject(projectId);

              if (project) {
                return (
                  <Alert key={`${logistic.logistics_id}-${projectId}-${index}`} variant={project.deliverType === 'delivery' ? 'success' : 'danger'}>
                    {project.deliverType === 'delivery' ? <ArrowRightCircle /> : <ArrowLeftCircle />} {project.projects_customer_name} <br />{' '}
                    {project.projects_address} <br /> {project.projects_zipcode} {project.projects_city} <br />{' '}
                    {project.projects_driving_times.length !== 0 &&
                      project.projects_driving_times.map((drivingTimeId) => {
                        const foundDrivingTimes = findDrivingTimes(drivingTimeId);
                        if (foundDrivingTimes) {
                          return <span key={foundDrivingTimes.driving_times_id}>{foundDrivingTimes.driving_times_name}</span>;
                        }
                        return null;
                      })}
                    <CloseButton className="btn-delete" onClick={() => removeLogisticItem('project', projectId, logistic)} />
                    {project.files.length === 0 ? null : (
                      <div className="pdf-popup" ref={ref}>
                        <Button size="sm" variant="secondary" onClick={handleClick}>
                          Vis PDF
                        </Button>

                        <Overlay show={show} target={target} placement="right" container={ref} containerPadding={20}>
                          <Popover>
                            <Popover.Body>
                              {project.files.map((file, index) => (
                                <Row key={index}>
                                  <Col sm={12}>
                                    <p>{file.project_files_name}</p>
                                    <embed src={file.project_files_url + '#zoom=100'} type="application/pdf" width="800px" height="800px"></embed>
                                  </Col>
                                </Row>
                              ))}
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </div>
                    )}
                  </Alert>
                );
              }
            })}
        </Col>
      </Row>
    </>
  );
};
