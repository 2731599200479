import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab, Alert } from 'react-bootstrap';
import { TurnoverCard } from './turnover-card';
import { getLocalStorage, setLocalStorage } from 'lib/localStorage';

export const Turnover: React.FC = () => {
  const [department, setDepartment] = useState(getLocalStorage('turnover-department') || 'fyn');

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setLocalStorage('turnover-department', key);
      setDepartment(key);
    }
  };

  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    setInterval(reloadPage, 12 * 60 * 60 * 1000); // reload once every 12 hours
  }, []);

  return (
    <>
      <Row className="department-tabs-wrapper">
        <Col>
          <Tabs defaultActiveKey={department} id="tabs-department" className="mb-3" onSelect={(k) => handleSelectTab(k)}>
            <Tab eventKey="sjælland" title="Sjælland">
              <Col>{'sjælland' === department && <TurnoverCard department={department} />}</Col>
            </Tab>
            <Tab eventKey="fyn" title="Fyn">
              <Col>{'fyn' === department && <TurnoverCard department={department} />}</Col>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Alert key="info" variant="secondary">
            Omsætning hentes ud fra alle projekter uanset status i Rentman. Dvs. alt fra `Concept´ til `Confirmed´. Dataen hentes ud fra `usageperiod_start`
          </Alert>
        </Col>
      </Row>
    </>
  );
};
