import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Form } from 'react-bootstrap';

import { SimpleModal } from 'components/modal';
import { getCars, createCar, updateCar, deleteCar } from 'Api';

import type { CarInterface } from 'pages/logistics-tool/types';

export const Cars: React.FC = () => {
  const initalCarData: CarInterface = {
    car_id: 0,
    car_name: '',
    car_created: '',
    car_updated: '',
  };

  const [cars, setCars] = useState<CarInterface[]>([]);
  const [showModal, setShowModal] = useState({
    addCar: false,
    changeCar: false,
    deleteCar: false,
  });
  const [car, setCar] = useState<CarInterface>(initalCarData);

  const getCarsApi = async (): Promise<void> => {
    const cars = await getCars();

    if (cars) {
      setCars(cars.data);
    }
  };

  const changeCarModalTriggered = async (car: CarInterface): Promise<void> => {
    setCar(car);
    setShowModal({
      ...showModal,
      changeCar: true,
    });
  };

  const addCarModalTriggered = async (): Promise<void> => {
    setCar(initalCarData);
    setShowModal({
      ...showModal,
      addCar: true,
    });
  };

  const deleteCarModalTriggered = async (car: CarInterface): Promise<void> => {
    setCar(car);
    setShowModal({
      ...showModal,
      deleteCar: true,
    });
  };

  const closeModals = async (): Promise<void> => {
    setCar(initalCarData);
    setShowModal({
      addCar: false,
      changeCar: false,
      deleteCar: false,
    });
  };

  const saveNewCar = async (): Promise<void> => {
    const crewSave = await createCar(car.car_name);
    if (crewSave) {
      await closeModals();
      await getCarsApi();
    } else {
      await closeModals();
    }
  };

  const saveCarChanges = async (): Promise<void> => {
    const crewSave = await updateCar(car.car_id, car.car_name);
    if (crewSave) {
      await closeModals();
      await getCarsApi();
    } else {
      await closeModals();
    }
  };

  const carDeleted = async (): Promise<void> => {
    const crewDeletedCall = await deleteCar(car.car_id);

    if (crewDeletedCall) {
      await closeModals();
      await getCarsApi();
    } else {
      await closeModals();
    }
  };

  const handleCarValueChanged = async (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>): Promise<void> => {
    setCar({
      ...car,
      [event.target.id]: event.target.value,
    });
  };

  useEffect(() => {
    getCarsApi();
  }, []);

  return (
    <>
      <Button onClick={() => addCarModalTriggered()}>Tilføj Bil</Button>
      <Table striped bordered responsive className="orderlist">
        <thead>
          <tr>
            <th>Vogn ID</th>
            <th>Vognnavn.</th>
            <th>Opdateret</th>
            <th>Oprettet</th>
            <th>Handlinger</th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car, index) => (
            <tr key={index}>
              <td>{car.car_id}</td>
              <td>{car.car_name}</td>
              <td className="text-align-end"> {new Date(car.car_created).toLocaleString('da-DK', { timeZone: 'GMT' })}</td>
              <td className="text-align-end">{new Date(car.car_updated).toLocaleString('da-DK', { timeZone: 'GMT' })}</td>
              <td className="text-align-end">
                <Button onClick={() => changeCarModalTriggered(car)}>Rediger</Button>
                <Button variant="danger" onClick={() => deleteCarModalTriggered(car)}>
                  Slet
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <SimpleModal
        showModal={showModal.addCar}
        title="Tilføj Bil"
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Luk"
        closeCallback={closeModals}
        saveButtonText="Tilføj"
        saveCallback={saveNewCar}
      >
        <Row>
          <Form.Group controlId={'car_name'}>
            <Form.Label>Bil navn</Form.Label>
            <Form.Control type="text" placeholder="Indtast bil navn" value={car.car_name} onChange={handleCarValueChanged} />
          </Form.Group>
        </Row>
      </SimpleModal>
      <SimpleModal
        showModal={showModal.changeCar}
        title={`Redigere ${car.car_name}`}
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Luk"
        closeCallback={closeModals}
        saveButtonText="Gem"
        saveCallback={saveCarChanges}
      >
        <Row>
          <Form.Group controlId={'car_name'}>
            <Form.Label>Bil navn</Form.Label>
            <Form.Control type="text" placeholder="Indtast bil navn" value={car.car_name} onChange={handleCarValueChanged} />
          </Form.Group>
        </Row>
      </SimpleModal>
      <SimpleModal
        showModal={showModal.deleteCar}
        title={`Sleting af ${car.car_name}`}
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Nej"
        closeCallback={closeModals}
        saveButtonVariant="danger"
        saveButtonText="Ja"
        saveCallback={carDeleted}
      >
        <Row>Er du sikker på at du ville fjerne?</Row>
      </SimpleModal>
    </>
  );
};
