import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Row, Table, Spinner, Alert } from 'react-bootstrap';
import { ApiResponseProject } from './types';
import { getProjectsByDate } from 'Api';
import { jsonToCSV } from 'components/jsonToCSV';
import { CustomToast } from 'components/toast';
import { formatPrice } from 'components/priceFormatter/formatPrice';

export const Projects: React.FC = () => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [projects, setProjects] = useState<ApiResponseProject[] | null>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const localProjectData = localStorage.getItem('projects');
      const localStartDate = localStorage.getItem('startDate');
      const localEndDate = localStorage.getItem('endDate');
      if (localProjectData) {
        setProjects(JSON.parse(localProjectData));
        setStartDate(localStartDate);
        setEndDate(localEndDate);
        return;
      }
    };
    fetchData();
    setLoading(false);
  }, []);

  const getTableData = async () => {
    setLoading(true);

    if (!startDate || !endDate) {
      setErrorMessage('* Både periode start og periode slut skal være valgt.');
      setLoading(false);
      return;
    }

    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrorMessage('* Periode slut skal være senere end periode start.');
        setLoading(false);
        return;
      }
      setErrorMessage(null);
      const fullStartDate = `${startDate} 00:00:00`;
      const fullEndDate = `${endDate} 23:59:59`;
      const response = await getProjectsByDate(fullStartDate, fullEndDate);
      if (response?.data) {
        setProjects(response.data);
        localStorage.setItem('projects', JSON.stringify(response.data));
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
      }
      setLoading(false);
      setShowToast(true);
    }
  };

  const downloadCSV = () => {
    if (projects?.length === 0) {
      return;
    }
    if (projects) {
      const header = [
        'Start dato',
        'Slut dato',
        'Projekt ID',
        'Projekt navn',
        'Status',
        'Udlejning',
        'Salg',
        'Ansatte',
        'Transport',
        'Andet',
        'Forsikring',
        'Total',
      ];

      const headerKeyMapping = {
        'Start dato': 'usageperiod_start',
        'Slut dato': 'usageperiod_end',
        'Projekt ID': 'projects_id',
        'Projekt navn': 'projects_name',
        Status: 'projects_status',
        Udlejning: 'projects_rental_price',
        Salg: 'projects_sale_price',
        Ansatte: 'projects_crew_price',
        Transport: 'projects_transport_price',
        Andet: 'projects_other_price',
        Forsikring: 'projects_insurance_price',
        Total: 'projects_amount',
      };

      const csvString = jsonToCSV(projects, header, headerKeyMapping);

      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = url;
      a.download = `projects - ${startDate} - ${endDate}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
  };

  return (
    <Col>
      <Row>
        <Form className="export-form">
          <Row>
            <FormGroup className="form-inline-group">
              <Form.Label>Periode Start:</Form.Label>
              <Form.Control className="form-container" type="date" value={startDate || ''} onChange={(e) => setStartDate(e.target.value)}></Form.Control>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="form-inline-group">
              <Form.Label>Periode slut:</Form.Label>
              <Form.Control className="form-container" type="date" value={endDate || ''} onChange={(e) => setEndDate(e.target.value)}></Form.Control>
              <div className="error-container">
                <p className={`error-message ${!errorMessage && 'hidden'}`}>{errorMessage || 'Placeholder'}</p>
              </div>
            </FormGroup>
          </Row>
          <Row className="btn-container">
            <Button onClick={getTableData} className="btn-show">
              Vis data for periode
            </Button>
            <Button onClick={downloadCSV} disabled={!projects || projects.length === 0} className="btn-dl">
              Download CSV
            </Button>
          </Row>
          <Row className="export-alert">
            <Alert variant="secondary">Projekter hentes hvis `usageperiod_start` er inden for, eller på de to valgte datoer.</Alert>
          </Row>
        </Form>
      </Row>
      <Row>
        <Table striped bordered responsive className="orderlist">
          <thead>
            <tr>
              <th>Start dato</th>
              <th>Slut dato</th>
              <th>Projekt ID</th>
              <th>Projekt navn</th>
              <th>Status</th>
              <th>Udlejning</th>
              <th>Salg</th>
              <th>Ansatte</th>
              <th>Transport</th>
              <th>Andet</th>
              <th>Forsikring</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={12} style={{ textAlign: 'center' }}>
                  <Spinner className="custom-spinner" />
                </td>
              </tr>
            ) : (
              projects
                ?.filter((project) => project !== null && project?.usageperiod_end && project?.usageperiod_start)
                .map((project, index) => (
                  <tr key={index}>
                    <td>{project.usageperiod_start.split('T')[0]}</td>
                    <td>{project.usageperiod_end.split('T')[0]}</td>
                    <td>{project.projects_id}</td>
                    <td>{project.projects_name}</td>
                    <td>{project.projects_status}</td>
                    <td>{formatPrice(project.projects_rental_price)}</td>
                    <td>{formatPrice(project.projects_sale_price)}</td>
                    <td>{formatPrice(project.projects_crew_price)}</td>
                    <td>{formatPrice(project.projects_transport_price)}</td>
                    <td>{formatPrice(project.projects_other_price)}</td>
                    <td>{formatPrice(project.projects_insurance_price)}</td>
                    <td>{formatPrice(project.projects_amount)}</td>
                  </tr>
                ))
            )}
          </tbody>
        </Table>
      </Row>
      <Row className="toast-b-right">
        {projects?.length === 0 ? (
          <CustomToast
            duration={10000}
            show={showToast}
            title={'An error occoured retrieving data.'}
            message={`There was is no data between ${startDate} and ${endDate}`}
            type="error"
            onClose={() => setShowToast(false)}
          />
        ) : (
          <CustomToast
            duration={3000}
            show={showToast}
            title={'Successfully retrieved data!'}
            message={`Retrieved data from ${startDate} to ${endDate}`}
            type="success"
            onClose={() => setShowToast(false)}
          />
        )}
      </Row>
    </Col>
  );
};
