import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { LogisticsGridTripsOverview } from './trips-overview';
import { getTrips } from 'Api';

import type { LogisticsGridTripsInterface, TripsInterface } from '../logistics-tool/types';

export const LogisticsGridTrips: React.FC<LogisticsGridTripsInterface> = ({ cars, crews, times, deliverProjects, returnProjects, date, department }) => {
  const [trips, setTrips] = useState<TripsInterface[]>([]);

  useEffect(() => {
    getTripsApi();
  }, []);

  const getTripsApi = async () => {
    const trips = await getTrips(date, department);

    if (trips) {
      setTrips(trips.data);
    }
  };

  return (
    <Row className="mt-4">
      {trips.length !== 0 &&
        trips.map((trip: TripsInterface) => {
          if (trip.logistics.length !== 0) {
            return (
              <Col key={trip.trip_id} sm={12} className="mb-4">
                <strong>Tur {trip.trip_order}</strong>
                <LogisticsGridTripsOverview
                  trip={trip}
                  cars={cars}
                  crews={crews}
                  times={times}
                  deliverProjects={deliverProjects}
                  returnProjects={returnProjects}
                />
              </Col>
            );
          } else {
            return null;
          }
        })}
    </Row>
  );
};
