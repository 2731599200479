import React, { useState, useEffect } from 'react';
import { MainPage } from 'pages/main';
import { Row, Col, Form, Button } from 'react-bootstrap';

const LoginPage: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password === '2023') {
      onLogin();
    } else {
      alert('Forkert kodeord');
    }
  };

  return (
    <Row className="login justify-content-center align-items-center mt-5">
      <Col sm={4}>
        <h2>Login</h2>
        <Form onSubmit={handleLoginSubmit}>
          <Form.Group controlId="formPassword">
            <Form.Label>Kodeord</Form.Label>
            <Form.Control type="password" value={password} onChange={handlePasswordChange} />
          </Form.Group>
          <Button className="dark-blue mt-2" type="submit">
            Login
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(isAuthenticated);
  }, []);

  return <div>{isAuthenticated ? <MainPage /> : <LoginPage onLogin={handleLogin} />}</div>;
};

export default App;
