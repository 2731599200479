import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import { Calendar3, GraphUpArrow } from 'react-bootstrap-icons';
import { TurnoverObject } from './types';

import moment from 'moment';

interface Props {
  turnoverData: TurnoverObject;
}

const thisYear = new Date().getFullYear();

// Date range function for week
const getDateRange = (weekNumber: number) => {
  const year = moment().year(thisYear); // Replace with your desired year
  const startDate = year.isoWeek(weekNumber).startOf('isoWeek').format('DD.MM.YYYY');
  const endDate = year.isoWeek(weekNumber).endOf('isoWeek').format('DD.MM.YYYY');
  return { startDate, endDate };
};

const WeekView: React.FC<Props> = ({ turnoverData }) => (
  <>
    {Object.keys(turnoverData).map((key: string) => {
      const data = turnoverData[Number(key)];

      return (
        <Card key={data.week}>
          <Card.Body>
            <Card.Title>
              <Badge>Uge {data.week}</Badge>
            </Card.Title>
            <Card.Subtitle>
              <Calendar3 />
              <span className="text-muted">{`${getDateRange(data.week).startDate} - ${getDateRange(data.week).endDate}`}</span>
            </Card.Subtitle>
            <Card.Text>
              <GraphUpArrow />
              Oms.: <span className="turnover">{`${new Intl.NumberFormat('da-DK').format(data.total_turnover)} DKK`}</span>
            </Card.Text>
          </Card.Body>
        </Card>
      );
    })}
  </>
);

export default WeekView;
