import { ApiResponseInvoice, ApiResponseProject } from 'pages/export/types';

export const jsonToCSV = (jsonArray: ApiResponseInvoice[] | ApiResponseProject[], header: string[], headerKeyMapping: Record<string, string>): string => {
  const priceFields = [
    'projects_rental_price',
    'projects_sale_price',
    'projects_crew_price',
    'projects_transport_price',
    'projects_other_price',
    'projects_insurance_price',
    'projects_amount',
    'project_rental_price',
    'project_sale_price',
    'project_crew_price',
    'project_transport_price',
    'project_other_price',
    'project_insurance_price',
    'project_total_price',
  ];

  const dateFields = ['date', 'usageperiod_start', 'usageperiod_end'];

  const projectIdField = ['project'];

  let csvString = '';
  const headerString = header.join(';');
  csvString += headerString + '\n';

  jsonArray.forEach((obj: ApiResponseInvoice | ApiResponseProject) => {
    const row: (string | number)[] = [];
    header.forEach((headerEl) => {
      const objKey = headerKeyMapping[headerEl];
      let value: string | number | undefined;
      if ('id' in obj) {
        value = obj[objKey as keyof ApiResponseInvoice];
      } else if ('projects_id' in obj) {
        value = obj[objKey as keyof ApiResponseProject];
      }
      if (value !== undefined && priceFields.includes(objKey)) {
        value = parseFloat(value as unknown as string).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (value !== undefined && dateFields.includes(objKey)) {
        if (typeof value === 'string') {
          const formattedDate = value.split('T')[0];
          value = formattedDate;
        }
      } else if (value !== undefined && projectIdField.includes(objKey)) {
        if (typeof value === 'string') {
          const formattedId = value.split('/').pop();
          value = formattedId;
        }
      }
      if (value !== undefined) {
        row.push(value);
      }
    });
    csvString += row.join(';') + '\n';
  });
  return csvString;
};
