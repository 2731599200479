import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { OrderListData } from './types';

// API
import { getOrderlist } from 'Api';

interface Props {
  department: string;
}

export const OrderListTable: React.FC<Props> = ({ department }) => {
  const [orders, setOrders] = useState<OrderListData[]>([]);

  useEffect(() => {
    const fetchOrders = async (): Promise<void> => {
      const response = await getOrderlist(department, 10);

      if (response) {
        // Sort orders by delivery date
        const sortedOrders = response.data.sort((a: OrderListData, b: OrderListData) => {
          const dateA = new Date(a.projects_delivery_date).getTime();
          const dateB = new Date(b.projects_delivery_date).getTime();
          return dateA - dateB;
        });

        setOrders(sortedOrders);
      }
    };

    fetchOrders();
  }, [department]);

  return (
    <>
      <Table striped bordered responsive className="orderlist">
        <thead>
          <tr>
            <th>Projekt ID</th>
            <th>Kundenr.</th>
            <th>Kundenavn</th>
            <th>Anledning</th>
            <th>Leveringsdato</th>
            <th>Brugsdato</th>
            <th>Status</th>
            <th>Kontaktperson</th>
            <th>Afdeling</th>
            <th>Total</th>
            <th>Postnummer</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td>{order.projects_id}</td>
              <td>{order.projects_customer_id}</td>
              <td>{order.projects_customer_name}</td>
              <td>{order.projects_note || order.projects_name}</td>
              <td className="text-align-end"> {new Date(order.projects_delivery_date).toLocaleDateString('da-DK', { timeZone: 'GMT' })}</td>
              <td className="text-align-end">{new Date(order.projects_used_date).toLocaleDateString('da-DK', { timeZone: 'GMT' })}</td>
              <td>{order.projects_status}</td>
              <td>{order.projects_crew_name}</td>
              <td>{order.projects_department}</td>
              <td className="text-align-end">{`${new Intl.NumberFormat('da-DK').format(order.projects_amount)} DKK`}</td>
              <td className="text-align-end">{order.projects_zipcode ? order.projects_zipcode : 'Afhentning'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
