import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Tab, Alert } from 'react-bootstrap';
import { OrderListTable } from './order-list-table';
import { getLocalStorage, setLocalStorage } from 'lib/localStorage';

export const OrderList: React.FC = () => {
  const [department, setDepartment] = useState(getLocalStorage('orderlist-department') || 'fyn');

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setLocalStorage('orderlist-department', key);
      setDepartment(key);
    }
  };

  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    setInterval(reloadPage, 1 * 60 * 60 * 1000); // reload once every 1 hours
  }, []);

  return (
    <>
      <Row className="department-tabs-wrapper">
        <Col>
          <Tabs defaultActiveKey={department} id="tabs-department" className="mb-3" onSelect={(k) => handleSelectTab(k)}>
            <Tab eventKey="sjælland" title="Sjælland">
              {'sjælland' === department && <OrderListTable department={department} />}
            </Tab>
            <Tab eventKey="fyn" title="Fyn">
              {'fyn' === department && <OrderListTable department={department} />}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Alert key="info" variant="secondary">
            Ordreliste henter projekter, som har status `Prepped´ eller `Confirmed´ i Rentman.
          </Alert>
        </Col>
      </Row>
    </>
  );
};
