import React from 'react';
import { Row, Col, Accordion, Button } from 'react-bootstrap';
import { ArrowLeftCircle, ArrowRightCircle, PersonFill, Truck } from 'react-bootstrap-icons';
import { Active, Over, useDraggable, useDndMonitor } from '@dnd-kit/core';

import { getCounters } from 'Api';

import type { LogisticsDragAndDropInterface, ProjectInterface, CarInterface, CrewInterface } from './types';

export const LogisticsDragAndDrop: React.FC<LogisticsDragAndDropInterface> = ({
  cars,
  crews,
  deliverProjects,
  returnProjects,
  projectCounter,
  setProjectCounter,
  carCounter,
  setCarCounter,
  crewCounter,
  setCrewCounter,
  date,
  department,
}) => {
  const getProjectCounter = (projectId: number): number => {
    if (!projectCounter || projectId in projectCounter === false) {
      return 0;
    }

    for (const key of Object.keys(projectCounter)) {
      if (parseInt(`${key}`) === parseInt(`${projectId}`)) {
        return projectCounter[Number(key)];
      }
    }

    return 0;
  };

  const getCarCounter = (carId: number): number => {
    if (!carCounter || carId in carCounter === false) {
      return 0;
    }

    for (const key of Object.keys(carCounter)) {
      if (parseInt(`${key}`) === parseInt(`${carId}`)) {
        return carCounter[Number(key)];
      }
    }

    return 0;
  };

  const getCrewCounter = (crewId: number): number => {
    if (!crewCounter || crewId in crewCounter === false) {
      return 0;
    }

    for (const key of Object.keys(crewCounter)) {
      if (parseInt(`${key}`) === parseInt(`${crewId}`)) {
        return crewCounter[Number(key)];
      }
    }

    return 0;
  };

  useDndMonitor({
    async onDragEnd(event) {
      const active: Active = event.active;
      const over: Over | null = event.over;

      if (!active || !active.data || !active.data.current) {
        return;
      }

      if (!over || !over.data || !over.data.current) {
        return;
      }

      if (over.data.current.accepts.includes(active.data.current.type)) {
        const tmpCounters = await getCounters(date, department);
        if (!tmpCounters) {
          return;
        }

        if (active.data.current.type === 'project') {
          setProjectCounter(tmpCounters.data.counters.projects);
        }

        if (active.data.current.type === 'car') {
          setCarCounter(tmpCounters.data.counters.cars);
        }

        if (active.data.current.type === 'crew') {
          setCrewCounter(tmpCounters.data.counters.crews);
        }
      }
    },
  });

  return (
    <>
      <Row className="mt-4 dnd-selections">
        <Col lg={12}>
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Projekter - Levering</Accordion.Header>
              <Accordion.Body>
                {deliverProjects.length !== 0 &&
                  deliverProjects.map((project) => {
                    return (
                      <ProjectItem
                        key={`new-deliver-project-${project.projects_id}`}
                        project={{ ...project, deliverType: 'delivery', counter: getProjectCounter(project.projects_id) }}
                      />
                    );
                  })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr className="mt-4" />
        </Col>

        <Col lg={12}>
          <Accordion flush>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Projekter - Retur</Accordion.Header>
              <Accordion.Body>
                {returnProjects.length !== 0 &&
                  returnProjects.map((project) => {
                    return (
                      <ProjectItem
                        key={`new-return-project-${project.projects_id}`}
                        project={{ ...project, deliverType: 'returning', counter: getProjectCounter(project.projects_id) }}
                      />
                    );
                  })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr className="mt-4" />
        </Col>

        <Col lg={12}>
          <Accordion flush>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Biler</Accordion.Header>
              <Accordion.Body>
                {cars.length !== 0 &&
                  cars.map((car) => car.car_id && <CarItem key={`new-car-${car.car_id}`} car={{ ...car, counter: getCarCounter(car.car_id) }} />)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr className="mt-4" />
        </Col>

        <Col lg={12}>
          <Accordion flush>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Personale</Accordion.Header>
              <Accordion.Body>
                {crews.length !== 0 &&
                  crews.map((crew) => crew.crew_id && <CrewItem key={`new-crew-${crew.crew_id}`} crew={{ ...crew, counter: getCrewCounter(crew.crew_id) }} />)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr className="mt-4" />
        </Col>
      </Row>
    </>
  );
};

const CarItem = ({ car }: { key?: string | number; car: CarInterface }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `car-${car.car_id}`,
    data: {
      type: 'car',
      id: car.car_id,
    },
  });

  let style = {};
  if (transform) {
    style = {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    };
  }

  return (
    <Button className="gray" style={style} {...listeners} {...attributes} ref={setNodeRef}>
      <Truck />
      {car.car_name} <b>{car.counter}</b>
    </Button>
  );
};

const CrewItem = ({ crew }: { key?: string | number; crew: CrewInterface }) => {
  const { listeners, attributes, setNodeRef, transform } = useDraggable({
    id: `crew-${crew.crew_id}`,
    data: {
      type: 'crew',
      id: crew.crew_id,
    },
  });

  let style = {};
  if (transform) {
    style = {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    };
  }

  return (
    <Button className="gray" style={style} {...listeners} {...attributes} ref={setNodeRef}>
      <PersonFill />
      {crew.crew_name} <b>{crew.counter}</b>
    </Button>
  );
};

const ProjectItem = ({ project }: { key?: string | number; project: ProjectInterface }) => {
  const { listeners, attributes, setNodeRef, transform } = useDraggable({
    id: `${project.deliverType}-project-${project.projects_id}`,
    data: {
      type: 'project',
      id: project.projects_id,
    },
  });

  let style = {};
  if (transform) {
    style = {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    };
  }

  return (
    <Button className="gray" style={style} {...listeners} {...attributes} ref={setNodeRef}>
      {project.deliverType === 'delivery' ? <ArrowRightCircle /> : <ArrowLeftCircle />}
      {project.projects_customer_name} <b>{project.counter}</b>
      {project.projects_type ? <span className="project-type">{project.projects_type}</span> : null}
    </Button>
  );
};
