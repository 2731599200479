import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Form } from 'react-bootstrap';

import { SimpleModal } from 'components/modal';
import { getCrews, createCrew, updateCrew, deleteCrew } from 'Api';

import type { CrewInterface } from 'pages/logistics-tool/types';

export const Crews: React.FC = () => {
  const initalCrewData: CrewInterface = {
    crew_id: 0,
    crew_name: '',
    crew_created: '',
    crew_updated: '',
  };

  const [crews, setCrews] = useState<CrewInterface[]>([]);
  const [showModal, setShowModal] = useState({
    addCrew: false,
    changeCrew: false,
    deleteCrew: false,
  });
  const [crew, setCrew] = useState<CrewInterface>(initalCrewData);

  const getCrewsApi = async (): Promise<void> => {
    const tmpCrew = await getCrews();

    if (tmpCrew) {
      setCrews(tmpCrew.data);
    }
  };

  const changeCrewModalTriggered = async (crew: CrewInterface): Promise<void> => {
    setCrew(crew);
    setShowModal({
      ...showModal,
      changeCrew: true,
    });
  };

  const addCrewModalTriggered = async (): Promise<void> => {
    setCrew(initalCrewData);
    setShowModal({
      ...showModal,
      addCrew: true,
    });
  };

  const deleteCrewModalTriggered = async (crew: CrewInterface): Promise<void> => {
    setCrew(crew);
    setShowModal({
      ...showModal,
      deleteCrew: true,
    });
  };

  const closeModals = async (): Promise<void> => {
    setCrew(initalCrewData);
    setShowModal({
      addCrew: false,
      changeCrew: false,
      deleteCrew: false,
    });
  };

  const saveNewCrew = async (): Promise<void> => {
    const crewSave = await createCrew(crew.crew_name);
    if (crewSave) {
      await closeModals();
      await getCrewsApi();
    } else {
      await closeModals();
    }
  };

  const saveCrewChanges = async (): Promise<void> => {
    const crewSave = await updateCrew(crew.crew_id, crew.crew_name);
    if (crewSave) {
      await closeModals();
      await getCrewsApi();
    } else {
      await closeModals();
    }
  };

  const crewDeleted = async (): Promise<void> => {
    const crewDeletedCall = await deleteCrew(crew.crew_id);
    if (crewDeletedCall) {
      await closeModals();
      await getCrewsApi();
    } else {
      await closeModals();
    }
  };

  const handleCrewValueChanged = async (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>): Promise<void> => {
    setCrew({
      ...crew,
      [event.target.id]: event.target.value,
    });
  };

  useEffect(() => {
    getCrewsApi();
  }, []);

  return (
    <>
      <Button onClick={() => addCrewModalTriggered()}>Tilføj Personale</Button>
      <Table striped bordered responsive className="orderlist">
        <thead>
          <tr>
            <th>Personale ID</th>
            <th>Personale navn.</th>
            <th>Opdateret</th>
            <th>Oprettet</th>
            <th>Handlinger</th>
          </tr>
        </thead>
        <tbody>
          {crews.map((crew, index) => (
            <tr key={index}>
              <td>{crew.crew_id}</td>
              <td>{crew.crew_name}</td>
              <td className="text-align-end"> {new Date(crew.crew_created).toLocaleString('da-DK', { timeZone: 'GMT' })}</td>
              <td className="text-align-end">{new Date(crew.crew_updated).toLocaleString('da-DK', { timeZone: 'GMT' })}</td>
              <td className="text-align-end">
                <Button onClick={() => changeCrewModalTriggered(crew)}>Rediger</Button>
                <Button variant="danger" onClick={() => deleteCrewModalTriggered(crew)}>
                  Slet
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <SimpleModal
        showModal={showModal.addCrew}
        title="Tilføj Personale"
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Luk"
        closeCallback={closeModals}
        saveButtonText="Tilføj"
        saveCallback={saveNewCrew}
      >
        <Row>
          <Form.Group controlId={'crew_name'}>
            <Form.Label>Personale navn</Form.Label>
            <Form.Control type="text" placeholder="Indtast personale navn" value={crew.crew_name} onChange={handleCrewValueChanged} />
          </Form.Group>
        </Row>
      </SimpleModal>
      <SimpleModal
        showModal={showModal.changeCrew}
        title={`Redigere ${crew?.crew_name}`}
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Luk"
        closeCallback={closeModals}
        saveButtonText="Gem"
        saveCallback={saveCrewChanges}
      >
        <Row>
          <Form.Group controlId={'crew_name'}>
            <Form.Label>Personale navn</Form.Label>
            <Form.Control type="text" placeholder="Indtast personale navn" value={crew.crew_name} onChange={handleCrewValueChanged} />
          </Form.Group>
        </Row>
      </SimpleModal>
      <SimpleModal
        showModal={showModal.deleteCrew}
        title={`Sleting af ${crew?.crew_name}`}
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Nej"
        closeCallback={closeModals}
        saveButtonVariant="danger"
        saveButtonText="Ja"
        saveCallback={crewDeleted}
      >
        <Row>Er du sikker på at du ville fjerne?</Row>
      </SimpleModal>
    </>
  );
};
