import { getInvoicesByDate } from 'Api';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Row, Table, Container, Spinner, Alert } from 'react-bootstrap';
import { ApiResponseInvoice } from './types';
import { jsonToCSV } from '../../components/jsonToCSV';
import { CustomToast } from 'components/toast';
import { formatPrice } from 'components/priceFormatter/formatPrice';

export const Invoices: React.FC = () => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [invoices, setInvoices] = useState<ApiResponseInvoice[] | null>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const localInvoiceData = localStorage.getItem('invoices');
      const localStartDate = localStorage.getItem('startDate');
      const localEndDate = localStorage.getItem('endDate');
      if (localInvoiceData) {
        setInvoices(JSON.parse(localInvoiceData));
        setStartDate(localStartDate);
        setEndDate(localEndDate);
        return;
      }
    };
    fetchData();
    setLoading(false);
  }, []);

  const getTableData = async () => {
    setLoading(true);
    if (startDate && endDate) {
      if (startDate > endDate) {
        setErrorMessage('* Periode slut skal være højere end periode start.');
        setLoading(false);
        return;
      }
      setErrorMessage(null);
      const fullStartDate = `${startDate} 00:00:00`;
      const fullEndDate = `${endDate} 23:59:59`;
      const response = await getInvoicesByDate(fullStartDate, fullEndDate);

      if (response?.data) {
        setInvoices(response.data);
        localStorage.setItem('invoices', JSON.stringify(response.data));
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
      }
      setLoading(false);
      setShowToast(true);
    }
  };

  const downloadCSV = () => {
    if (invoices?.length === 0) {
      return;
    }
    if (invoices) {
      const header = [
        'Dato',
        'Faktura ID',
        'Faktura nr.',
        'Faktura navn',
        'Projekt ID',
        'Udlejning',
        'Salg',
        'Ansatte',
        'Transport',
        'Andet',
        'Forsikring',
        'Total',
      ];

      const headerKeyMapping = {
        Dato: 'date',
        'Faktura ID': 'id',
        'Faktura nr.': 'number',
        'Faktura navn': 'displayname',
        'Projekt ID': 'project',
        Udlejning: 'project_rental_price',
        Salg: 'project_sale_price',
        Ansatte: 'project_crew_price',
        Transport: 'project_transport_price',
        Andet: 'project_other_price',
        Forsikring: 'project_insurance_price',
        Total: 'project_total_price',
      };

      const csvString = jsonToCSV(invoices, header, headerKeyMapping);

      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = url;
      a.download = `invoices - ${startDate} - ${endDate}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
  };

  return (
    <Col className="export-container">
      <Row>
        <Form className="export-form">
          <Row>
            <FormGroup className="form-inline-group">
              <Form.Label>Periode Start:</Form.Label>
              <Form.Control className="form-container" type="date" value={startDate || ''} onChange={(e) => setStartDate(e.target.value)}></Form.Control>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="form-inline-group">
              <Form.Label>Periode slut:</Form.Label>
              <Form.Control className="form-container" type="date" value={endDate || ''} onChange={(e) => setEndDate(e.target.value)}></Form.Control>
              <div className="error-container">
                <p className={`error-message ${!errorMessage && 'hidden'}`}>{errorMessage || 'Placeholder'}</p>
              </div>
            </FormGroup>
          </Row>
          <Row className="btn-container">
            <Button onClick={getTableData} className="btn-show">
              Vis data for periode
            </Button>
            <Button onClick={downloadCSV} disabled={!invoices || invoices.length === 0} className="btn-dl">
              Download CSV
            </Button>
          </Row>
          <Row className="export-alert">
            <Alert variant="secondary">Fakturaer hentes hvis `date` er inden for, eller på de to valgte datoer.</Alert>
          </Row>
        </Form>
      </Row>
      <Table striped bordered responsive className="orderlist">
        <thead>
          <tr>
            <th>Dato</th>
            <th>Faktura ID</th>
            <th>Faktura nr.</th>
            <th>Faktura navn</th>
            <th>Projekt ID</th>
            <th>Udlejning</th>
            <th>Salg</th>
            <th>Ansatte</th>
            <th>Transport</th>
            <th>Andet</th>
            <th>Forsikring</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={12} style={{ textAlign: 'center' }}>
                <Spinner className="custom-spinner" />
              </td>
            </tr>
          ) : (
            invoices
              ?.filter((invoice) => invoice !== null && invoice?.date && invoice?.id)
              .map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice.date.split('T')[0]}</td>
                  <td>{invoice.id}</td>
                  <td>{invoice.number}</td>
                  <td>{invoice.displayname}</td>
                  <td>{invoice.project ? invoice.project.split('/').pop()?.slice(-3) : invoice.project}</td>
                  <td>{formatPrice(invoice.project_rental_price)}</td>
                  <td>{formatPrice(invoice.project_sale_price)}</td>
                  <td>{formatPrice(invoice.project_crew_price)}</td>
                  <td>{formatPrice(invoice.project_transport_price)}</td>
                  <td>{formatPrice(invoice.project_other_price)}</td>
                  <td>{formatPrice(invoice.project_insurance_price)}</td>
                  <td>{formatPrice(invoice.project_total_price)}</td>
                </tr>
              ))
          )}
        </tbody>
      </Table>
      <Container className="toast-b-right">
        {invoices?.length === 0 ? (
          <CustomToast
            duration={3000}
            show={showToast}
            title={'An error occoured retrieving data.'}
            message={`There was no data between ${startDate} and ${endDate}`}
            type="error"
            onClose={() => setShowToast(false)}
          />
        ) : (
          <CustomToast
            duration={3000}
            show={showToast}
            title={'Successfully retrieved data!'}
            message={`Retrieved data from ${startDate} to ${endDate}`}
            type="success"
            onClose={() => setShowToast(false)}
          />
        )}
      </Container>
    </Col>
  );
};
