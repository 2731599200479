import React from 'react';
import { Card } from 'react-bootstrap';
import { useDroppable } from '@dnd-kit/core';

import type { LogisticsInterface } from '../types';

export const TripDropZone: React.FC<{
  customKey: string;
  customId: number | string;
  logistic: LogisticsInterface | null;
  children: JSX.Element;
  index: number;
}> = ({ customKey, customId, logistic, children, index }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: customKey,
    data: {
      id: customId,
      logistic: logistic,
      accepts: ['crew', 'car', 'project'],
    },
  });
  const style = {
    background: isOver ? 'lightblue' : 'white',
  };

  return (
    <Card id={customKey} ref={setNodeRef} style={style}>
      <Card.Header>Hold {index + 1}</Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};
