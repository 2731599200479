import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { LogisticsGridTrips } from './trips';
import { getLogisticsData } from 'Api';

import type { DateAndDeparmentInterface, CarInterface, CrewInterface, ProjectInterface, DrivingTimesInterface } from '../logistics-tool/types';

export const LogisticsGrid: React.FC<DateAndDeparmentInterface> = ({ date, department }) => {
  const [deliverProjects, setDeliverProjects] = useState<ProjectInterface[]>([]);
  const [returnProjects, setReturnProjects] = useState<ProjectInterface[]>([]);
  const [cars, setCars] = useState<CarInterface[]>([]);
  const [crews, setCrews] = useState<CrewInterface[]>([]);
  const [times, setTimes] = useState<DrivingTimesInterface[]>([]);

  const callApis = async (): Promise<void> => {
    const logisticsData = await getLogisticsData(date, department);

    if (logisticsData) {
      setCars(logisticsData.data.cars);
      setCrews(logisticsData.data.crews);
      setTimes(logisticsData.data.drivingTimes);
      setDeliverProjects(logisticsData.data.projects.deliverList);
      setReturnProjects(logisticsData.data.projects.returnList);
    }
  };

  useEffect(() => {
    callApis();
  }, []);

  return (
    <Row>
      <Col sm={12}>
        <h4>
          <strong>Ture</strong>
        </h4>
        <LogisticsGridTrips
          cars={cars}
          crews={crews}
          times={times}
          deliverProjects={deliverProjects}
          returnProjects={returnProjects}
          date={date}
          department={department}
        />
      </Col>
    </Row>
  );
};
