import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DndContext, closestCenter } from '@dnd-kit/core';

import { LogisticsDragAndDrop } from './drag-and-drop';
import { LogisticsGridTrips } from './trips';
import { getLogisticsData } from 'Api';

import type { DateAndDeparmentInterface, CarInterface, CrewInterface, ProjectInterface, CounterObject, DrivingTimesInterface } from './types';

export const LogisticsGrid: React.FC<DateAndDeparmentInterface> = ({ date, department }) => {
  const [deliverProjects, setDeliverProjects] = useState<ProjectInterface[]>([]);
  const [returnProjects, setReturnProjects] = useState<ProjectInterface[]>([]);
  const [cars, setCars] = useState<CarInterface[]>([]);
  const [crews, setCrews] = useState<CrewInterface[]>([]);
  const [times, setTimes] = useState<DrivingTimesInterface[]>([]);
  const [projectCounter, setProjectCounter] = useState<CounterObject>({});
  const [carCounter, setCarCounter] = useState<CounterObject>({});
  const [crewCounter, setCrewCounter] = useState<CounterObject>({});

  const callApis = async (): Promise<void> => {
    const logisticsData = await getLogisticsData(date, department);

    if (logisticsData) {
      setCars(logisticsData.data.cars);
      setCrews(logisticsData.data.crews);
      setTimes(logisticsData.data.drivingTimes);
      setDeliverProjects(logisticsData.data.projects.deliverList);
      setReturnProjects(logisticsData.data.projects.returnList);
      setProjectCounter(logisticsData.data.counters.projects);
      setCarCounter(logisticsData.data.counters.cars);
      setCrewCounter(logisticsData.data.counters.crews);
    }
  };

  useEffect(() => {
    callApis();
  }, []);

  return (
    <DndContext collisionDetection={closestCenter}>
      <Row>
        <Col sm={4}>
          <h4>
            <strong>Drag and drop område</strong>
          </h4>
          <LogisticsDragAndDrop
            cars={cars}
            crews={crews}
            deliverProjects={deliverProjects}
            returnProjects={returnProjects}
            date={date}
            department={department}
            projectCounter={projectCounter}
            setProjectCounter={setProjectCounter}
            carCounter={carCounter}
            setCarCounter={setCarCounter}
            crewCounter={crewCounter}
            setCrewCounter={setCrewCounter}
          />
        </Col>
        <Col sm={8}>
          <h4>
            <strong>Ture</strong>
          </h4>
          <LogisticsGridTrips
            cars={cars}
            crews={crews}
            times={times}
            deliverProjects={deliverProjects}
            returnProjects={returnProjects}
            date={date}
            department={department}
            setProjectCounter={setProjectCounter}
            setCarCounter={setCarCounter}
            setCrewCounter={setCrewCounter}
          />
        </Col>
      </Row>
    </DndContext>
  );
};
