import React from 'react';
import { Row, Card } from 'react-bootstrap';

import { LogisticsGridTripsContent } from './trips-content';
import { LogisticsGridTripsHeading } from './trips-heading';

import type { TripOverviewInterface } from './types';

export const LogisticsGridTripsOverview: React.FC<TripOverviewInterface> = ({ trip, cars, crews, times, deliverProjects, returnProjects }) => {
  return (
    <Row className="logistics-grid-trips mt-3">
      {trip.logistics.length !== 0 &&
        trip.logistics.map((logistic, index) => {
          return (
            <Card key={logistic.logistics_id}>
              <Card.Header>Hold {index + 1}</Card.Header>
              <Card.Body>
                <LogisticsGridTripsHeading logistic={logistic} allCars={cars} selectedCars={logistic.logistics_cars} />{' '}
                <LogisticsGridTripsContent
                  logistic={logistic}
                  allCrews={crews}
                  allTimes={times}
                  selectedCrews={logistic.logistics_crews}
                  selectedProjects={logistic.logistics_projects}
                  deliverProjects={deliverProjects}
                  returnProjects={returnProjects}
                />
              </Card.Body>
            </Card>
          );
        })}
    </Row>
  );
};
