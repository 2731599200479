import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { Truck } from 'react-bootstrap-icons';

import type { CarInterface } from '../logistics-tool/types';
import type { TripHeadingInterface } from './types';

export const LogisticsGridTripsHeading: React.FC<TripHeadingInterface> = ({ logistic, allCars, selectedCars }) => {
  const findCar = (carId: number): CarInterface | null => {
    let foundCar: CarInterface | null = null;

    for (const car of allCars) {
      if (car.car_id === carId) {
        foundCar = car;
      }
    }

    return foundCar;
  };

  return (
    <>
      <Row className="mb-2">
        <Col sm={12}>
          {selectedCars.length !== 0 &&
            selectedCars.map((carId, index) => {
              const car = findCar(carId);

              if (car) {
                return (
                  <Alert key={`${logistic.logistics_id}-${carId}-${index}`} variant="secondary">
                    <Truck /> {car.car_name}
                  </Alert>
                );
              } else {
                return null;
              }
            })}
        </Col>
      </Row>
    </>
  );
};
