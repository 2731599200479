import React, { useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LogisticsGrid } from './grid';

import type { DepartmentInterface } from './types';

export const LogisticsDateTabs: React.FC<DepartmentInterface> = ({ department }) => {
  const day = new Date();
  const [activeTab, setActiveTab] = useState<string>(day.toISOString().slice(0, 10));

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setActiveTab(key);
    }
  };

  return (
    <>
      <Row className="logistics-date-tabs-wrapper">
        <Col>
          <Tabs defaultActiveKey={activeTab} id="tabs-dates" className="mb-3" onSelect={(k) => handleSelectTab(k)}>
            {[...Array(10)].map((x, i) => {
              if (i !== 0) {
                day.setDate(day.getDate() + 1);
              }

              const dateKey = day.toISOString().slice(0, 10);

              return (
                <Tab key={dateKey} eventKey={dateKey} title={`${day.getDate()}/${day.getMonth() + 1}-${day.getFullYear()}`}>
                  {dateKey === activeTab && <LogisticsGrid date={activeTab} department={department} />}
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
