import { CustomToastProps } from './types';
import React, { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';

export const CustomToast: React.FC<CustomToastProps> = ({ show, title, message, imgSrc, timeAgo, type, onClose, duration }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [show, onClose, duration]);

  const headerClass = type === 'success' ? 'toast-success' : 'toast-error';

  return (
    <div className="justify-content-center">
      <Toast show={show} onClose={onClose}>
        <Toast.Header className={headerClass}>
          {imgSrc && <img src={imgSrc} className="rounded me-2" alt="Toast Image" />}
          <strong className="me-auto">{title}</strong>
          {timeAgo && <small>{timeAgo}</small>}
        </Toast.Header>
        <Toast.Body className="toast-background">{message}</Toast.Body>
      </Toast>
    </div>
  );
};
