import React, { useState, useEffect } from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { TurnoverObject } from './types';
import { getLocalStorage, setLocalStorage } from 'lib/localStorage';

// API
import { getYearlyTurnoverWeek } from 'Api';
import { getYearlyTurnoverMonth } from 'Api';

import WeekView from './week';
import MonthView from './month';

interface Props {
  department: string;
}

export const TurnoverCard: React.FC<Props> = ({ department }) => {
  const [turnoverData, setTurnoverData] = useState<TurnoverObject>({});
  const [turnoverDataMonth, setTurnoverDataMonth] = useState<TurnoverObject>({});
  const [selectedView, setSelectedView] = useState(getLocalStorage('turnover-period') || 'week');

  const thisYear = new Date().getFullYear();

  useEffect(() => {
    const fetchTurnoverData = async (): Promise<void> => {
      const response = await getYearlyTurnoverWeek(department, thisYear);

      if (response) {
        setTurnoverData(response.data);
      }
    };

    fetchTurnoverData();
  }, [department]);

  useEffect(() => {
    const fetchTurnoverDataMonth = async (): Promise<void> => {
      const response = await getYearlyTurnoverMonth(department, thisYear);

      if (response) {
        setTurnoverDataMonth(response.data);
      }
    };

    fetchTurnoverDataMonth();
  }, [department]);

  const handleViewChange = (view: string | null) => {
    if (view) {
      setLocalStorage('turnover-period', view);
      setSelectedView(view);
    }
  };

  return (
    <>
      <Col className="turnover-period">
        <div>Visning</div>
        <Dropdown onSelect={handleViewChange}>
          <Dropdown.Toggle variant="primary" id="dropdown-view-toggle">
            {selectedView === 'week' ? 'Uge' : 'Måned'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="week">Uge</Dropdown.Item>
            <Dropdown.Item eventKey="month">Måned</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>

      <Col className="turnover-grid">{selectedView === 'week' && <WeekView turnoverData={turnoverData} />}</Col>
      <Col className="turnover-grid">{selectedView === 'month' && <MonthView turnoverDataMonth={turnoverDataMonth} />}</Col>
    </>
  );
};
