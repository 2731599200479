import React, { useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

import { getLocalStorage, setLocalStorage } from 'lib/localStorage';
import { Cars } from './cars';
import { Crews } from './crews';

export const Admin: React.FC = () => {
  const [type, setType] = useState<string>(getLocalStorage('admin-type') || 'crew');

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setLocalStorage('admin-type', key);
      setType(key);
    }
  };

  return (
    <Row className="department-tabs-wrapper">
      <Col>
        <Tabs defaultActiveKey={type} id="tabs-department" className="mb-3" onSelect={(k) => handleSelectTab(k)}>
          <Tab eventKey="crew" title="Personale">
            {'crew' === type && <Crews />}
          </Tab>
          <Tab eventKey="car" title="Biler">
            {'car' === type && <Cars />}
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};
