import React from 'react';
import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';

import type { ModalInterface } from './types';

export const SimpleModal: React.FC<React.PropsWithChildren<ModalInterface>> = ({
  title,
  subtitle,
  showCloseButton,
  closeButtonText,
  closeButtonVariant,
  showSaveButton,
  saveButtonVariant,
  saveButtonText,
  showModal,
  closeCallback,
  saveCallback,
  children,
}) => {
  const handleModalClose = async () => {
    if (closeCallback) {
      closeCallback();
    }
  };

  return (
    <Modal size="xl" show={showModal} className="modal" backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {title && <h1>{title}</h1>}
          {subtitle && <h5>{subtitle}</h5>}
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Container>{children}</Container>
        </Modal.Body>
        <Modal.Footer>
          <Container fluid>
            <Row>
              {showCloseButton && showCloseButton === true && (
                <Col xs={6} className="d-flex justify-content-end">
                  <Button variant={closeButtonVariant ? closeButtonVariant : undefined} onClick={() => (handleModalClose ? handleModalClose() : null)}>
                    {closeButtonText ? closeButtonText : 'Luk'}
                  </Button>
                </Col>
              )}
              {showSaveButton && showSaveButton === true && (
                <Col xs={6}>
                  <Button variant={saveButtonVariant ? saveButtonVariant : undefined} onClick={() => (saveCallback ? saveCallback() : null)}>
                    {saveButtonText ? saveButtonText : 'Gem'}
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
