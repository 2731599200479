import React, { useState } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LogisticsDateTabs } from './tabs';
import { getLocalStorage, setLocalStorage } from 'lib/localStorage';

export const LogisticsOverview: React.FC = () => {
  const [department, setDepartment] = useState(getLocalStorage('logistics-overview-department') || 'fyn');

  const handleSelectTab = (key: string | null) => {
    if (key) {
      setLocalStorage('logistics-overview-department', key);
      setDepartment(key);
    }
  };

  return (
    <>
      <Row className="department-tabs-wrapper">
        <Col>
          <Tabs defaultActiveKey={department} id="tabs-department" className="mb-3" onSelect={(k) => handleSelectTab(k)}>
            <Tab eventKey="sjælland" title="Sjælland">
              {department === 'sjælland' && <LogisticsDateTabs department={department} />}
            </Tab>
            <Tab eventKey="fyn" title="Fyn">
              {department === 'fyn' && <LogisticsDateTabs department={department} />}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
