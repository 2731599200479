import React, { useEffect, useState } from 'react';
import { Row, Col, Button, CloseButton } from 'react-bootstrap';
import { Active, Over, useDndMonitor } from '@dnd-kit/core';

import { SimpleModal } from 'components/modal';
import { LogisticsGridTripsOverview } from './trips-overview';
import { getTrips, createTrip, deleteTrip, createLogistic, updateLogistic, deleteLogistic, getCounters } from 'Api';

import type { LogisticsGridTripsInterfaceWithCounter, TripsInterface, LogisticsInterface } from './types';

export const LogisticsGridTrips: React.FC<LogisticsGridTripsInterfaceWithCounter> = ({
  cars,
  crews,
  times,
  deliverProjects,
  returnProjects,
  date,
  department,
  setProjectCounter,
  setCarCounter,
  setCrewCounter,
}) => {
  const initalTripData: TripsInterface = {
    trip_id: 0,
    trip_date: '',
    trip_department: '',
    trip_order: 0,
    logistics: [],
  };

  const [trips, setTrips] = useState<TripsInterface[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [trip, setTrip] = useState<TripsInterface>(initalTripData);

  useEffect(() => {
    getTripsApi();
  }, []);

  const getTripsApi = async () => {
    const trips = await getTrips(date, department);

    if (trips) {
      setTrips(trips.data);
    }
  };

  const handleCreateTrip = async () => {
    let currentTripOrder = 0;
    if (trips.length !== 0) {
      currentTripOrder = trips[trips.length - 1].trip_order;
    }
    const tripCreated = await createTrip(date, department, currentTripOrder + 1);

    if (tripCreated) {
      await getTripsApi();
    }
  };

  const closeModals = async (): Promise<void> => {
    setTrip(initalTripData);
    setShowModal(false);
  };

  const deleteModalTriggered = async (trip: TripsInterface): Promise<void> => {
    setTrip(trip);
    setShowModal(true);
  };

  const handleDeleteTrip = async () => {
    if (!trip) {
      return;
    }

    const deletedTrip = await deleteTrip(trip.trip_id);

    if (deletedTrip) {
      await closeModals();
      await getTripsApi();
    } else {
      await closeModals();
    }
  };

  const removeLogisticItem = async (type: 'car' | 'crew' | 'project', id: number, logistic: LogisticsInterface) => {
    const payload: { logistics_cars: number[]; logistics_crews: number[]; logistics_projects: number[] } = {
      logistics_cars: logistic.logistics_cars,
      logistics_crews: logistic.logistics_crews,
      logistics_projects: logistic.logistics_projects,
    };

    if (type === 'car') {
      payload.logistics_cars = payload.logistics_cars.filter((carId) => carId !== id);
    } else if (type === 'crew') {
      payload.logistics_crews = payload.logistics_crews.filter((crewId) => crewId !== id);
    } else if (type === 'project') {
      payload.logistics_projects = payload.logistics_projects.filter((projectId) => projectId !== id);
    }

    let apiCall;
    if (payload.logistics_cars.length === 0 && payload.logistics_crews.length === 0 && payload.logistics_projects.length === 0) {
      apiCall = await deleteLogistic(logistic.logistics_id);
    } else {
      apiCall = await updateLogistic(logistic.logistics_id, payload);
    }

    if (apiCall) {
      await getTripsApi();
    }

    const counters = await getCounters(date, department);
    if (counters) {
      setProjectCounter(counters.data.counters.projects);
      setCarCounter(counters.data.counters.cars);
      setCrewCounter(counters.data.counters.crews);
    }
  };

  useDndMonitor({
    async onDragEnd(event) {
      const active: Active = event.active;
      const over: Over | null = event.over;

      if (!active || !active.data || !active.data.current) {
        return;
      }

      if (!over || !over.data || !over.data.current) {
        return;
      }

      if (over.data.current.accepts.includes(active.data.current.type)) {
        if (over.id.toString().includes('new-logistic') === true) {
          const newCars: number[] = [];
          const newCrews: number[] = [];
          const newProjects: number[] = [];

          if (active.data.current.type === 'crew') {
            newCrews.push(active.data.current.id);
          }

          if (active.data.current.type === 'car') {
            newCars.push(active.data.current.id);
          }

          if (active.data.current.type === 'project') {
            newProjects.push(active.data.current.id);
          }

          const newLogisticCreated = await createLogistic(over.data.current.id, newCars, newCrews, newProjects);
          if (newLogisticCreated) {
            await getTripsApi();
          }
        } else {
          if (active.data.current.type === 'crew') {
            if (over.data.current.logistic.logistics_crews.length === 0) {
              over.data.current.logistic.logistics_crews = [...over.data.current.logistic.logistics_crews, active.data.current.id];
            } else if (over.data.current.logistic.logistics_crews.some((crewId: number) => crewId === active.data.current?.id) === false) {
              over.data.current.logistic.logistics_crews = [...over.data.current.logistic.logistics_crews, active.data.current.id];
            }
          }

          if (active.data.current.type === 'car') {
            if (over.data.current.logistic.logistics_cars.length === 0) {
              over.data.current.logistic.logistics_cars = [...over.data.current.logistic.logistics_cars, active.data.current.id];
            } else if (over.data.current.logistic.logistics_cars.some((carId: number) => carId === active.data.current?.id) === false) {
              over.data.current.logistic.logistics_cars = [...over.data.current.logistic.logistics_cars, active.data.current.id];
            }
          }

          if (active.data.current.type === 'project') {
            if (over.data.current.logistic.logistics_projects.length === 0) {
              over.data.current.logistic.logistics_projects = [...over.data.current.logistic.logistics_projects, active.data.current.id];
            } else if (over.data.current.logistic.logistics_projects.some((projectId: number) => projectId === active.data.current?.id) === false) {
              over.data.current.logistic.logistics_projects = [...over.data.current.logistic.logistics_projects, active.data.current.id];
            }
          }

          const updatedLogistic = await updateLogistic(over.data.current.logistic.logistics_id, {
            logistics_trip_id: over.data.current.logistic.logistics_trip_id,
            logistics_cars: over.data.current.logistic.logistics_cars,
            logistics_crews: over.data.current.logistic.logistics_crews,
            logistics_projects: over.data.current.logistic.logistics_projects,
            logistics_order: over.data.current.logistic.logistics_order,
          });

          if (updatedLogistic) {
            await getTripsApi();
          }
        }
      }
    },
  });

  return (
    <Row className="mt-4">
      {trips.length !== 0 &&
        trips.map((trip: TripsInterface) => {
          return (
            <Col key={trip.trip_id} sm={12} className="mb-4">
              <strong>
                Tur {trip.trip_order} <CloseButton className="btn-delete" onClick={() => deleteModalTriggered(trip)} />
              </strong>
              <LogisticsGridTripsOverview
                trip={trip}
                cars={cars}
                times={times}
                crews={crews}
                deliverProjects={deliverProjects}
                returnProjects={returnProjects}
                removeLogisticItem={removeLogisticItem}
              />
            </Col>
          );
        })}
      <Col sm={12} className="mb-4">
        <Button variant="primary" onClick={handleCreateTrip}>
          Tilføj tur
        </Button>
      </Col>
      <SimpleModal
        showModal={showModal}
        title={`Sleting af Tur ${trip?.trip_order}`}
        showCloseButton={true}
        showSaveButton={true}
        closeButtonText="Nej"
        closeCallback={closeModals}
        saveButtonVariant="danger"
        saveButtonText="Ja"
        saveCallback={handleDeleteTrip}
      >
        <Row>Er du sikker på at du ville fjerne?</Row>
      </SimpleModal>
    </Row>
  );
};
